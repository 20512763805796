@media only screen and (max-width: 400px) {
  .doc {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .docName {
    padding-left: 0px !important;
  }
}
